import React, { useState } from "react";
import AdminPage from "./AdminPage"; // Keep the AdminPage import
import Navbar from "./Navbar";

import "../Styles/AdminPageLogin.css";
const AdminPageLogin = () => {
  const [userId, setUserId] = useState(""); // Track User ID
  const [password, setPassword] = useState(""); // Track Password
  const [error, setError] = useState(""); // Track login errors
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [dangerPopup, setDangerPopup] = useState(false); // Track if danger popup is shown

  const handleLogin = () => {
    const validUserId = "Drbilal232";
    const validPassword = "Maibil@1059";

    // Check if user credentials are valid
    if (userId === validUserId && password === validPassword) {
      setError(""); // Clear any previous error messages
      setIsLoggedIn(true); // Set login status to true
    } else {
      setError("Invalid User ID or Password"); // Set error message if login fails
      setDangerPopup(true); // Show danger popup if login fails
      setTimeout(() => {
        setDangerPopup(false); // Hide danger popup after 2 seconds
      }, 2000);
    }
  };

  return (
    <>
      <Navbar />
      <div id="login-popup5454 ">
        {!isLoggedIn ? ( // Show login popup only when not logged in
          <div id="login-popup5454">
            <h1 id="loghead">Admin Login</h1>
            <input
              type="text"
              placeholder="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)} // Update User ID state
              id="user-id-input"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update Password state
              id="password-input"
            />

             <div  id="login-button" > <button onClick={handleLogin}>
              Login
            </button></div>
            
            {error && <p id="error-message">{error}</p>}{" "}
            {/* Display error in red */}
          </div>
        ) : (
          // Show AdminPage if logged in
          <div id="happy-popup6565">
            <AdminPage /> {/* Display the AdminPage component here */}
          </div>
        )}

        {dangerPopup && ( // Show danger popup if login fails
          <div id="danger-popup7676">
            <h3 style={{ color: "red" }}>⚠️ Danger! Invalid credentials.</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminPageLogin;
