import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Styles/InvestorInsights.css"; // Import the CSS file

export default function FarmersGuide() {
  return (
    <>
      <Navbar />
      <div id="background-imageinverstr">
        <div>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <h2>TINY TO PLENTY</h2>
        </div>
        <div   id="frmrlgo">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>
       
<h1 id="invhead"> FARMER`S HANDBOOK  </h1>
<h2>For</h2>
 <h2> High Density Apple Plantation</h2>  


 <hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>

          <h1  id="invhead">Overview</h1>
      <p> This booklet contains the contents having relevance to the farmers
engaged in ultra-high density apple cultivation. It is just an
overview of the training contents that the clients of  <strong>MAIBIL
HORTICULTURAL SERVICES </strong>will receive prior to the
plantation exercise by the enterprise. This booklet will provide
brief insights to the cultivators about the proper management of
the orchard.
Note: This booklet is not a substitute to the in-house training of
<strong>MAIBIL HORTICULTURAL SERVICES.</strong></p>
<hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>
          <h1 id="invhead">INDEX</h1>

          <ol>
    <li>Introduction</li>
    <li>Site Selection for High-Density Apple Plantation</li>
    <li>Site Preparation</li>
    <li>Choosing the Right Apple Variety</li>
    <li>Orchard Design Principles</li>
    <li>Planting Density</li>
    <li>Tree Training Systems</li>
    <li>Pest and Disease Management</li>
  </ol>

  <hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>
<span id="desimpinv">CHAPTER 1: 
</span>
<strong>Introduction:</strong>

<p>High-density apple plantations are a more popular choice for the
modern apple orchards. they offer higher yields per hectare and
enables more efficient use of space. This farmer’s handbook provides
them essential guidelines to enable them analyze the feasibility of the
project, brief description about the site preparation and the other
follow ups after planting. Though the farmers shall undergo offline or
online training, this handbook is a gist of their training summary.
High-density apple plantations represent a modern approach to
apple cultivation that maximizes yield, optimizes land use, and
enhances orchard management efficiency. Traditional apple orchards
often require large spaces between trees, with planting densities
ranging from 100 to 300 trees per hectare. However, advancements
in horticultural practices and the development of dwarfing rootstocks
have enabled the establishment of high-density orchards, where tree
density can range from 1,000 to 4,000 trees per hectare or more. This
shift has revolutionized apple farming, particularly in regions with
limited arable land.
The primary goal of high-density apple planting is to increase the
number of trees per unit area, thereby boosting overall fruit
production. This approach relies heavily on the use of dwarfing
rootstocks, which control the size of the trees, ensuring that they
remain compact and manageable. In addition to higher yields, highdensity orchards offer several other benefits, such as earlier fruit
production, improved fruit quality, and more efficient labor and
resource use. For instance, the smaller tree size in high-density
systems facilitates easier pruning, thinning, and harvesting, leading
to reduced labor costs and increased productivity.
Moreover, high-density apple plantations are well-suited to modern
orchard management techniques, including precision agriculture
4
and mechanization. The uniformity in tree size and spacing allows
for the use of advanced irrigation systems, frost protection measures,
and pest and disease management strategies. This level of control not
only enhances the health and productivity of the orchard but also
contributes to sustainable farming practices by optimizing water and
nutrient use.
However, the transition to high-density planting is not without its
challenges. The initial establishment costs are significantly higher
than traditional orchards, requiring investments in specialized
trellising systems, high-quality rootstocks, and advanced irrigation
infrastructure. Additionally, the success of high-density plantations
depends on careful planning and management, including proper tree
training and pruning techniques, to avoid issues such as excessive
shading, disease susceptibility, and reduced fruit size.
In conclusion, high-density apple plantations offer a promising
approach to modern apple cultivation, particularly in regions facing
land constraints or seeking to maximize production efficiency. While
the initial costs and management requirements are high, the
potential for increased yields, improved fruit quality, and enhanced
sustainability makes high-density planting an attractive option for
orchardists looking to stay competitive in the evolving agricultural
landscape.</p>


<hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>

<span id="desimpinv"> CHAPTER 2: </span>
<strong>Site Selection for High-Density Apple
Plantation :</strong>

<p>Choosing the right site for a high-density apple plantation is critical
to the success and profitability of the orchard. Below is a detailed
guide on the key factors to consider when selecting a site.
Temperate climate is ideal for the growth and sustenance of apple
trees. Temperature is the most important element of Climate. For
growing Season, Apples require a growing season with temperatures
between 18-24°C (64-75°F). Extreme heat can stress trees, while cool,
consistent temperatures promote healthy growth. Apples need a
certain number of chilling hours (hours below 7°C or 45°F) during
winter to break dormancy and ensure proper flowering. The
requirement varies by variety but typically ranges from 700 to 1,500
hours. The late spring frosts can damage blossoms, so the site should
be relatively frost-free during the blooming period. Elevations or
locations with good air drainage can reduce frost risk.
Balanced annual Precipitation of 600-800 mm (24-31 inches) is an
essential requirement for an effective apple growth. Insufficient
rainfall can be supplemented with irrigation to prevent the water
deficiency.
Rainfall should be well-distributed throughout the year, with
minimal heavy rains during the harvest period to avoid fruit cracking
and disease issues.
Humidity refers to the water vapour content in atmosphere. Relative
humidity refers to the percentage of water vapour with respect to its
saturation level in atmosphere at a particular temperature. At 100%
relative humidity, the water vapours start to condense and results in
formation of dew or water droplets. Higher relative humidity levels
can increase the risk of fungal diseases like apple scab. Ideal sites
have moderate humidity levels, particularly during the growing
season.
6
Good air circulation helps reduce humidity and minimizes disease
risks.
Soil is one of the most important factors influencing the decision for
apple cultivation. For apples, the texture should be of loamy soils
with a balanced mixture of sand, silt, and clay with a good drainage.
Avoid heavy clay soils, as they can lead to poor drainage and root
diseases. The soil should be deep enough (at least 60-100 cm or 24-
39 inches) to allow for proper root development. Shallow soils can
restrict root growth and limit access to nutrients and water.
Apples thrive in soils with a pH of 6.0 to 7.0. Soils that are too acidic
(below pH 5.5) or too alkaline (above pH 7.5) can affect nutrient
availability and tree health.
Lime can be added to raise soil pH, while sulfur can be used to lower
it. Regular soil testing is essential to monitor pH levels.
Apples require well-drained soils to prevent waterlogging, which can
lead to root rot and other issues. If the site has poor natural drainage,
consider installing drainage tiles or raised beds.The water table
should be deep enough to avoid saturation of the root zone, ideally
more than 1.5 meters (5 feet) below the surface.
The fertility of soils is another basis besides taking care of the earlier
mentioned factors, Ensure the soil is rich in essential nutrients,
particularly nitrogen, phosphorus, potassium, calcium, and
magnesium. Deficiencies should be corrected with appropriate
fertilizers or organic amendments. High organic matter content (3-
5%) improves soil structure, water retention, and fertility. Consider
incorporating compost or cover crops to enhance organic matter.
Slight slopes (2-5%) are ideal for apple orchards as they provide good
air and water drainage. Steep slopes can lead to soil erosion and
make mechanization difficult. North or northeast-facing slopes are
often preferred in warmer regions to protect trees from excessive heat
and sunburn. In cooler regions, south-facing slopes can help
maximize sun exposure and warmth.
7
Ideal elevation depends on the region. In tropical or subtropical
areas, higher elevations (1,000-2,500 meters) may be necessary to
provide the cooler temperatures needed for apple cultivation. In
temperate zones, elevations of 300-800 meters are often suitable.
Higher elevations with good air drainage can reduce the risk of frost
damage, especially during spring when trees are flowering.
Cold air tends to settle in low-lying areas, increasing the risk of frost.
Select a site with good air drainage, where cold air can move away
from the orchard, reducing the likelihood of frost pockets. Large
bodies of water can moderate temperatures and reduce frost risk, but
they can also increase humidity, leading to higher disease pressure.
A consistent and reliable source of water is essential, especially in
regions with irregular rainfall. Consider nearby rivers, wells, or
irrigation canals. Water used for irrigation should be of good quality,
low in salts, and free from contaminants. Saline water can cause soil
degradation and harm the trees.
Drip irrigation is the most efficient method for high-density apple
orchards, delivering water directly to the root zone with minimal
waste. Regions with seasonal rainfall, consider installing water
storage facilities such as ponds or tanks to ensure a steady supply
during dry periods.
High-density apple orchards require significant labor for planting,
pruning, harvesting, and other tasks. Ensure there is an adequate
supply of skilled labor nearby. If the site is remote, consider the
availability of housing or accommodation for seasonal workers.
The other miscellaneous factors include access to electricity which is
the most important for running irrigation systems, cold storage
facilities, and other essential infrastructure. Reliable communication
networks are necessary for farm management, market access, and
emergencies.
Conclusively, selecting the right site for a high-density apple
plantation involves careful consideration of climate, soil, topography,
8
water availability, and access to markets and infrastructure. By
thoroughly evaluating these factors, you can ensure that your
orchard is well-positioned for success, with healthy trees, high yields,
and sustainable practices</p>

<hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>

<span id="desimpinv" >CHAPTER 3: </span>
<strong>Site preparation :</strong>
<p> Proper site preparation is crucial for establishing a successful highdensity apple orchard. This step lays the foundation for healthy tree
growth, efficient orchard management, and long-term productivity.
Detailed site preparation involves several key tasks, including soil
preparation, drainage, layout planning, and infrastructure setup.
Below is a comprehensive guide to site preparation for a high-density
apple plantation.
</p>
<strong>
Soil Preparation: 
</strong>
<p>It involves several steps and processes. Before planting, conduct a
detailed soil analysis. Collect soil samples from various locations and
depths (typically 15-30 cm) across the intended orchard site.
Test for pH, nutrient levels (N, P, K, Ca, Mg, S, etc.), organic matter
content, soil texture, and potential contaminants like heavy metals
or herbicide residues.
Send the samples to a certified laboratory for analysis. The results
will guide your soil amendment and fertilization plans.
Soil pH Adjustment is highly recommendary. Apple trees prefer a
slightly acidic to neutral pH, ideally between 6.0 and 7.0. If the soil
is too acidic (pH below 6.0), apply agricultural lime to raise the pH.
The type and amount of lime depend on the soil type and current pH
level. If the soil is too alkaline (pH above 7.0), apply elemental sulfur
or acid-forming fertilizers to lower the pH.
Ensure that the soil is sufficiently fertile. Accordingly, the Soil
Fertility management is ensured by Incorporate organic matter, such
as compost or well-rotted manure, to improve soil structure, water
retention, and nutrient availability.
Based on soil test results, apply the necessary nutrients to correct
any deficiencies. Common pre-plant fertilizers include phosphorus
(e.g., superphosphate), potassium (e.g., potash), and micronutrients
(e.g., zinc, boron).
10
Consider planting a cover crop (e.g., legumes or grasses) a season
before planting. Cover crops can improve soil fertility, reduce erosion,
and suppress weeds.
</p>
<strong>

Soil Structure Improvement :
</strong>
<p>Deep till the soil to break up compacted layers and improve root
penetration. Subsoiling may be necessary if there is a hardpan
(compacted soil layer) below the topsoil.
Incorporate green manure crops (e.g., rye, clover) into the soil to
enhance organic matter content and improve soil structure.
Apply mulch, such as straw or wood chips, after planting to help
retain moisture, regulate soil temperature, and suppress weeds.</p>

<strong>

Drainage and Water Management :

</strong>
<p>
Conduct a percolation test to assess the soil's drainage capacity. Dig
a hole, fill it with water, and measure how quickly the water drains.
Poor drainage can lead to waterlogged conditions, which are
detrimental to apple trees.
Observe the natural drainage patterns of the site. Identify areas
prone to water accumulation or erosion and plan to address these
issues.
Grade the site to ensure water flows away from the tree rows. Install
surface drains or ditches if necessary to channel excess water away
from the orchard.
If the site has heavy clay soils or a high-water table, consider
installing subsurface drainage systems, such as perforated drain
tiles or French drains. These systems help remove excess water from
the root zone.
In areas with persistent waterlogging, sump pumps may be required
to actively remove excess water

</p>

<strong>

Irrigation System Setup :


</strong>

<p>Identify a reliable water source, such as a well, pond, or river, with
adequate water quality and quantity to meet the orchard's needs.
Drip irrigation is recommended for high-density apple orchards due
to its efficiency in water use and ability to deliver water directly to the
root zone. Micro-sprinklers are an alternative for sandy soils or where
broader coverage is needed.
Design the irrigation system to ensure uniform water distribution
across the orchard. Consider factors such as soil type, tree spacing,
and slope when determining emitter spacing and flow rates.
If water availability is seasonal, consider installing water storage
tanks or reservoirs to ensure a consistent water supply during dry
periods.</p>

<strong>

Site Layout and Infrastructure :


</strong>

<p>Orchard Layout Planning: Block Design: Divide the orchard into
blocks based on variety, rootstock, and intended harvest timing. This
simplifies management and reduces the risk of cross-contamination
between different varieties.
Row Orientation: For optimal sunlight exposure, orient rows northsouth. This orientation reduces shading and promotes uniform fruit
ripening. In areas with strong winds, consider windbreaks when
planning row orientation.
Access Roads: Plan for sufficient access roads and pathways to allow
for machinery movement, worker access, and efficient orchard
management. Typically, main access roads are 3-4 meters wide.</p>
<strong>

Fencing and Pest Control :


</strong>

<p>
Fencing: Install fencing around the orchard to protect against
wildlife, such as deer, rabbits, and other animals that can damage
trees or fruit. Use materials that are durable and appropriate for the
local wildlife pressure.
12
Pest Control: Plan for integrated pest management (IPM) strategies,
including monitoring, biological controls, and chemical interventions
as necessary. Consider installing insect traps and bird deterrents as
part of the pest control infrastructure.
Trellis and Support Systems: Trellis Installation: For high-density
orchards, install a trellis system to support dwarf apple trees, which
may not have strong natural anchorage. The trellis system typically
consists of posts, wires, and anchors.
Support Stakes: Individual tree stakes can also be used, especially in
systems where trellises are not practical. Stakes should be strong
and durable, capable of supporting the tree as it grows and bears
fruit.

</p>

<strong>

Environmental Considerations :


</strong>

<p>Erosion Control: Cover Crops: Plant cover crops or grasses between
tree rows to reduce soil erosion, improve soil structure, and enhance
water infiltration.
Terracing: On sloped sites, consider terracing to reduce erosion and
manage water runoff. Terraces can also help to improve access and
maintain soil fertility.
Biodiversity and Ecosystem Services: Habitat Creation: Preserve or
create habitats for beneficial insects, birds, and other wildlife that
contribute to pest control and pollination. Planting native species in
hedgerows or around the orchard can enhance biodiversity.
Water Conservation: Implement water-saving practices, such as
mulching and efficient irrigation, to conserve water and maintain soil
moisture levels.
Sustainable Practices: Organic Matter Management: Incorporate
organic farming practices, such as composting and using organic
fertilizers, to build soil health and reduce chemical inputs.
13
Energy Efficiency: Plan for energy-efficient operations by considering
the use of solar-powered equipment, energy-efficient irrigation
pumps, and other technologies that reduce the orchard's carbon
footprint.
Final Site Preparation Steps: Marking and Laying Out Tree Rows
Row Marking: Once the site is graded and prepared, mark the tree
rows using stakes, string, or GPS-guided equipment. Ensure the
rows are straight and evenly spaced according to the chosen planting
density.
Planting Holes: Dig planting holes at the designated locations. The
holes should be large enough to accommodate the root system of the
young trees without causing root curling or compression.
Final Soil Preparation
Fertilization: Apply any last-minute soil amendments, such as starter
fertilizers, directly into the planting holes. This ensures that the
young trees have access to essential nutrients as they establish.
Mulching: After planting, apply mulch around each tree to conserve
moisture, suppress weeds, and regulate soil temperature.
Infrastructure Setup
<strong> Install Irrigation: </strong>
Complete the installation of the irrigation system,
ensuring that all emitters or sprinklers are functioning correctly and
delivering water evenly. <strong> Set Up Trellises:</strong>
 Finish the installation of the trellis or staking system
before planting to avoid damaging the trees later</p>

<strong>

Conclusion
 :


</strong>

<p> Thorough site preparation is vital to the success of a high-density
apple orchard. By carefully preparing the soil, managing water
resources, planning the orchard layout, and setting up necessary
infrastructure, you can create a productive and sustainable orchard
that will thrive for many years. Each step of the preparation process,
from soil testing to final infrastructure setup, plays a crucial role in
ensuring the health and productivity of your apple trees.
</p>


<hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>
<span id="desimpinv">  CHAPTER 4: </span>
<strong>Choosing the Right Apple Variety  :</strong>



<p>When selecting an apple variety, consider the following factors:
  <strong>Climate Compatibility: </strong> Each variety requires a certain number of chilling hours
(hours below 7°C or 45°F) during winter to break dormancy.  <strong> The Low-Chill Varieties</strong>
 are suitable for warmer climates with fewer than 800 chilling hours
(e.g., ‘Anna,’ ‘Dorsett Golden’) while as, the   <strong> High-Chill Varieties require</strong> more
than 1,000 chilling hours, making them ideal for colder climates (e.g.,
‘Honeycrisp,’ ‘Fuji’). Some varieties bloom early and are more susceptible to late
spring frosts, while others bloom later and avoid frost damage. For frost-prone
areas, consider varieties that bloom later.
</p>

<strong > Market Demand and Consumer Preferences :</strong>

<p> Varieties with unique flavors, such as the sweet-tart balance of ‘Honeycrisp’ or
the crisp texture of ‘Gala,’ often command higher market prices. Consumers
prefer apples with bright, consistent coloring. Varieties like ‘Red Delicious’ and
‘Fuji’ are known for their attractive appearance. Choose varieties with good postharvest storage qualities, especially if you plan to store the apples for extended
periods. For example, ‘Granny Smith’ and ‘Cripps Pink’ (Pink Lady) have long
storage lives.</p>

<strong>Disease Resistance :
</strong>

<p>Apple scab is a common fungal disease that affects many apple varieties.
Varieties like ‘Liberty,’ ‘Enterprise,’ and ‘GoldRush’ are resistant to scab. Fire
blight is a bacterial disease that can devastate orchards. Resistant varieties
include ‘Enterprise,’ ‘GoldRush,’ and ‘Arkansas Black. Choose varieties with
resistance to powdery mildew, especially in regions with high humidity.
Examples include ‘Jonafree’ and ‘Prima.’
</p>
<strong> Pollination Requirements :
</strong>
<p>Most apple varieties are not self-fertile and require cross-pollination with another
compatible variety to produce fruit. Some self-fertile varieties, like ‘Golden
Delicious,’ can serve as pollinators for others.
15
Ensure you plant compatible pollinator varieties within close
proximity (typically within 30 meters or 100 feet) to ensure good fruit
set. For example, ‘Gala’ and ‘Fuji’ can pollinate each other.</p>

<strong> Other Aspects :</strong>
<p>Plant a mix of varieties that ripen at different times to extend your
harvest period and manage labor more effectively. For example: Early
Season varieties: ‘Gala,’ ‘Honeycrisp’ Mid-Season: ‘Golden Delicious,’
‘Jonathan’ Late Season: ‘Granny Smith,’ ‘Cripps Pink’
Varieties like ‘Gala,’ ‘Fuji,’ and ‘Honeycrisp’ are popular in the fresh
market, while others like ‘McIntosh’ and ‘Empire’ are often used for
processing (e.g., applesauce, juice, cider).
Consider specialty varieties for niche markets, such as heirloom
varieties (‘Ashmead’s Kernel’) or apples specifically for cider
production (‘Dabinett,’ ‘Kingston Black’).</p>


<span  id="desimpinv" >CHAPTER 5: </span>

<strong> Planting Density:</strong>
<p>Planting density refers to the number of trees planted per unit area,
usually expressed as trees per hectare (tph). In high-density apple
orchards, the goal is to maximize the number of trees while ensuring
that each tree has enough space for optimal growth and fruit
production.
</p>

<strong> Factors Influencing Planting Density :</strong> 
<p>Dwarfing rootstock: The more dwarfing the rootstock, the higher the
planting density. Dwarfing rootstocks like M9, M26, and B9 allow for
closer spacing because they produce smaller trees. Variety
Characteristics have a bearing. Some apple varieties have more
compact growth habits and can be planted closer together. Others,
with more vigorous growth, may require slightly more space.
Climate and Soil: In regions with fertile soils and abundant sunlight,
trees tend to grow more vigorously, potentially requiring slightly
wider spacing. Conversely, in less fertile soils or cooler climates, trees
may be planted closer together.
Irrigation and Nutrient Management: Efficient water and nutrient
management can support higher planting densities by ensuring that
each tree receives the resources it needs despite closer spacing.</p>

<strong> Common Planting Densities in High-Density Orchards :</strong>
<p> Super High-Density (SHD): 2,500-3,300 trees per hectare (tph). Trees
are spaced 0.9-1.2 meters apart within rows and 3-3.5 meters
between rows.
High-Density (HD): 1,500-2,500 tph. Trees are spaced 1.2-1.5 meters
apart within rows and 3.5-4 meters between rows.
Medium-Density: 800-1,200 tph. Trees are spaced 2-3 meters apart
within rows and 4-5 meters between rows.</p>

<strong> within rows and 4-5 meters between rows.
Traditional : </strong>
<p>: 200-400 tph. Trees are spaced 4-6 meters apart within
rows and 5-7 meters between rows.</p>

<hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>


          <span id="desimpinv">CHAPTER 6: </span>
          <strong>  Orchard Design Principles</strong>
          <p>Row Orientation
North-South Orientation: For optimal sunlight exposure, rows should
ideally be oriented north-south. This orientation allows for even
sunlight distribution throughout the day, reducing shading and
promoting uniform fruit ripening.
East-West Orientation: This can be considered in regions with strong
prevailing winds from the north or south, as it might reduce wind
damage. However, it can result in uneven sunlight distribution, with
one side of the tree receiving more sunlight.
Row and Tree Spacing
In-Row Spacing: The distance between trees within a row. For highdensity planting, in-row spacing is typically 0.9-1.5 meters. Closer
spacing promotes early canopy closure, which can increase yields in
the early years.
Between-Row Spacing: The distance between rows. For high-density
orchards, this is usually 3-4 meters. Adequate between-row spacing
is crucial to allow for machinery access, air circulation, and sunlight
penetration.
Canopy Management: The spacing should consider the eventual size
of the tree canopy. Canopies should just touch at maturity, allowing
for maximum sunlight interception while minimizing competition for
light.
Tree Training Systems
Tree training systems are designed to control tree shape, manage tree
size, and optimize light interception. Common systems in highdensity apple orchards include:
Vertical Axis: It has a single central leader with lateral branches that
are pruned to maintain a narrow, conical shape. It is Simple to
manage, good light distribution, and suitable for dwarf rootstocks
like M9.
It has a tree density of 2,000-3,000 tph.
18
Slender Spindle: It has a single central leader with short, evenly
spaced lateral branches. The branches are pruned to maintain a
narrow, cylindrical shape. The key features include efficient light
interception, easy to harvest, and suitable for very high-density
planting. It has a tree density of 2,500-3,300 tph.
Tall Spindle: the structure is similar to the slender spindle but with
a taller central leader. The tree height can reach up to 3 meters, with
minimal branching at the lower levels. It is advantageous for reasons
of maximum light interception, high early yields, and easy
mechanization. The tree density shall be between 2,500-3,500 tph.
Trellis Systems: in this system the trees are supported by a wire
trellis, with branches trained along horizontal wires. It is Ideal for
very high-density systems, good support for weak-rooted dwarf
rootstocks and excellent light distribution. The tree density is 3,000-
4,000 tph.
V-Trellis or Bi-Axis: in this structure, the trees are trained with two
main leaders, forming a "V" shape.
It is advantageous for Increased light penetration, reduced shading,
and improved air circulation, which can reduce disease pressure. The
tree density is 2,000-2,500 tph.</p>
<strong>  Irrigation and Fertigation Design</strong>
<p> Drip irrigation delivers water directly to the root zone through a
network of drip lines placed along each row. It enables efficient water
use, reduces weed growth, and can be combined with fertigation (the
application of fertilizers through the irrigation system). Fertigation
enables integration of nutrient delivery into the irrigation system. It
enables precise nutrient management, reduces labor, and improves
nutrient uptake. Regular monitoring of the soil and leaf nutrient
levels are essential to adjust fertigation schedules. The drip irrigation
shall be designed to ensure that the drip emitters are spaced
appropriately to cover the entire root zone as the trees mature.
</p>
<strong> Windbreaks and Shelterbelts
</strong>
<p>To protect the orchard from strong winds, which can cause physical
damage to trees, increase evaporation rates, and reduce pollination
19
efficiency. The plant windbreaks along the orchard's perimeter,
ideally at right angles to the prevailing winds. Use species that grow
tall and dense but do not compete excessively with the apple trees for
water and nutrients. The distance between windbreak rows and the
orchard should be at least 10-15 meters to minimize shading and
root competition.</p>
<strong> Canopy and Light Management</strong>
<strong> Light Interception</strong>
<strong> Objective:</strong>
<p> Maximize the amount of sunlight intercepted by the tree
canopy to enhance photosynthesis and fruit quality. The trees should
be pruned and trained to maintain an open canopy, allowing light to
penetrate to the lower branches. Avoid overly dense planting that can
lead to shading. As mentioned, a north-south row orientation
typically provides the best light distribution throughout the day.
</p>
<strong> Pruning and Training</strong>
 <p>Regular pruning is essential to maintain tree shape, remove
overcrowded branches, and ensure good air circulation. It also helps
in managing the tree's vigor and fruit load. Young trees need to be
trained to the desired shape using support systems like stakes, wires,
and trellises. Proper training ensures a strong framework for future
fruiting and ease of management.
Conclusively, designing a high-density apple orchard requires careful
planning and consideration of multiple factors, including planting
density, tree training systems, row orientation, and irrigation design.
By optimizing these elements, you can create an efficient, productive,
and sustainable orchard that maximizes yields and minimizes
inputs. This approach ensures that the orchard is well-prepared for
both current production needs and future technological
advancements.</p>

<hr></hr>   
    <div   id="secfrmrgd">   <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
          
          /></div>

          <span id="desimpinv"> CHAPTER 7:</span>

          <strong> Pest and disease management  :</strong><p> Pest and disease management in high-density apple plantations is
crucial for maintaining tree health, maximizing yields, and ensuring
fruit quality. High-density systems, while offering increased
productivity, can also present challenges due to the close proximity
of trees, which can facilitate the spread of pests and diseases. Here’s
a detailed guide on managing these risks effectively:</p>
<strong> Understanding the Pest and Disease Profile :
</strong>
<p>Common Pests:
Codling Moth (Cydia pomonella): Larvae burrow into apples, causing
significant damage.
Apple Maggot (Rhagoletis pomonella): Causes pitting and tunneling
in the fruit.
Aphids: Feed on sap, weakening trees and spreading viral diseases.
Mites: Spider mites and rust mites can cause leaf damage and affect
photosynthesis.
Leafrollers: Caterpillars that roll leaves and feed on them, leading to
defoliation.
Common Diseases:
Apple Scab (Venturia inaequalis): Causes dark lesions on leaves and
fruit, leading to premature leaf fall and reduced fruit quality.
Powdery Mildew (Podosphaera leucotricha): Affects young shoots,
leaves, and fruit, leading to stunted growth and reduced yield.
Fire Blight (Erwinia amylovora): A bacterial disease that causes
wilting and death of blossoms, shoots, and limbs.
Canker: Fungal infections that cause sunken lesions on the bark,
leading to branch dieback.
Cedar Apple Rust (Gymnosporangium juniperi-virginianae): Causes
orange spots on leaves and can lead to premature leaf drop.
Cultural Control Practices
21
Remove and destroy fallen leaves and fruit to reduce overwintering
sites for pests and disease spores.
Prune infected branches and dispose of them properly.
Maintain an open canopy structure to improve air circulation, which
reduces humidity levels that favor disease development.
Prune regularly to remove diseased wood and maintain tree shape,
which facilitates easier spraying.
Keep the orchard floor free from weeds, which can harbor pests and
compete for nutrients.
Biological Control
Encourage the presence of beneficial insects like ladybugs,
lacewings, and parasitic wasps that prey on aphids, mites, and other
pests.
Use products like Bacillus thuringiensis (Bt) for controlling caterpillar
pests or Beauveria bassiana for general insect control.
Planting flowers or cover crops that attract beneficial insects can help
control pest populations.
Chemical Control
Use targeted insecticides such as spinosad or pyrethroids for codling
moths and leafrollers.
Apply horticultural oils during dormant periods to control
overwintering eggs of mites and aphids.
Use systemic fungicides for diseases like powdery mildew and apple
scab, especially during the critical periods of leaf emergence and fruit
set.
Copper-based fungicides can be effective against fire blight but
should be used with caution to avoid phytotoxicity.
Develop a seasonal spray program based on pest and disease
scouting, ensuring proper timing and rotation of products to prevent
resistance.
22
Rotate chemical classes to reduce the risk of pests and pathogens
developing resistance to treatments.
Integrated Pest Management (IPM)
Regularly scout the orchard for signs of pests and diseases. Use
pheromone traps to monitor codling moth populations.
Implement action thresholds based on pest population levels to
determine when chemical controls are necessary.
Keep detailed records of pest and disease occurrences, weather
conditions, and treatment outcomes to refine management practices
over time.
Environmental and Regulatory Considerations
Avoid spraying insecticides during bloom when pollinators are active.
Use selective pesticides that are less harmful to beneficial insects.
Ensure proper irrigation practices to avoid creating conditions
conducive to disease development, such as overly wet soil.
Follow local regulations regarding pesticide use, including preharvest intervals and maximum residue limits.
Technology and Tools
Utilize weather-based models to predict the likelihood of disease
outbreaks like apple scab or fire blight, enabling timely interventions.
Use drones or satellite imagery to monitor tree health and identify
problem areas early.
Post-Harvest Management
Implement proper storage practices to prevent diseases like blue
mold (Penicillium expansum) and gray mold (Botrytis cinerea).
Regularly clean and disinfect storage areas and bins to reduce the
risk of disease spread.
23
We endeavor to provide the best services to our clients.
For end-to-end services for High density apple plantation: contact, </p>

<div id="invest-former">
            <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
            <section class="contact-info">
                <h2>Contact Information</h2>
                <p><strong>Address:</strong> 3RD FLOOR,  ASH MALL BUDGAM 191111</p>
                <p><strong>Phone:</strong> +919541786524, +919541786520, +918082555851</p>
                <p><strong>Email:</strong> <a href="mailto:info@maibil.in">info@maibil.in</a></p>
                <p><strong>Website:</strong> <a href="https://maibil.in">maibil.in</a></p>
            </section>
        </div>

<div id="background-image">
<div id="idQoutes">
        <h1 id="invhead" > Quotes in honour of the farmers and
        horticulturists</h1>

       <strong > 1. "A farmer’s life is grounded in the seasonality of nature, and
       nothing teaches patience like waiting for the harvest."</strong> 

       <strong> 2. "The best time to plant a tree was 20 years ago. The second
best time is now."</strong>
__Proverb

<strong> 3. "The apple cannot be stuck back on the tree of knowledge;
once we begin to see, we are doomed and challenged to seek
the strength to see more, not less."</strong>
– Arthur Miller

<strong> 4. "An apple tree is worth more than a harvest of gold."
– Spanish Proverb</strong>
<strong>5. "To plant a garden is to believe in tomorrow."</strong>
– Audrey Hepburn
</div>
</div>
      </div> 
      <Footer />
   
    </>
  );
}
