import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import AboutHDAP from "./AboutHDAP";
import FarmersGuide from "./FarmersGuide";
import InvestorInsights from "./InvestorInsights";
import Banner from "./Banner";
import AboutUs from "./AbousUs";
import Gallery from "./Gallery";
import SlotBook from "./SlotBook";
import Footer from "./Footer";
import AdminPageLogin from "./AdminPageLogin";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Main Page Route */}
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Banner />

              <AboutUs />
              <Gallery />
              <SlotBook />
              <Footer />
            </>
          }
        />

        <Route path="/maibil.in/AdminPageLogin" element={<AdminPageLogin />} />

        <Route path="/AboutHDAP" element={<AboutHDAP />} />
        <Route path="/FarmersGuide" element={<FarmersGuide />} />
        <Route path="/InvestorInsights" element={<InvestorInsights />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
