import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import Navbar from './Navbar';
import Footer from "./Footer";
import "../Styles/AdminPage.css";
const AdminPage = () => {
  const [notification, setNotification] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [pdfs, setPdfs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(' https://maibil-horticultural-services-1.onrender.com/fetch-data'); // Ensure the correct port
      setNotifications(response.data.notifications);
      setPdfs(response.data.pdfs);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire('Error', 'Failed to fetch data from the database.', 'error');
    }
  };

  const handleNotificationSubmit = async (e) => {
    e.preventDefault();

    if (!notification.trim()) {
      Swal.fire('Error', 'Notification cannot be empty.', 'error');
      return;
    }

    try {
      await axios.post(' https://maibil-horticultural-services-1.onrender.com/add-notification', { title: 'New Notification', message: notification });
      Swal.fire('Success', 'Notification Submitted Successfully', 'success');
      setNotification('');
      fetchData();
    } catch (error) {
      console.error('Error submitting notification:', error);
      Swal.fire('Error', 'Failed to Submit Notification', 'error');
    }
  };

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!pdfFile) {
      Swal.fire('Error', 'Please select a PDF to upload.', 'error');
      return;
    }

    if (pdfFile.size > 500 * 1024) {
      Swal.fire('Error', 'File size exceeds 500KB. Please choose a smaller file.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', pdfFile);

    try {
      const response = await axios.post('https://maibil-horticultural-services-1.onrender.com/upload-pdf', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      Swal.fire('Success', response.data.message, 'success');
      setPdfFile(null);
      fetchData();
    } catch (error) {
      console.error('Error uploading PDF:', error);
      Swal.fire('Error', 'Failed to Upload PDF.', 'error');
    }
  };

  const handleDeleteNotification = async (id) => {
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'This notification will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirm.isConfirmed) {
      try {
        await axios.delete(`https://maibil-horticultural-services-1.onrender.com/delete-notification/${id}`);
        Swal.fire('Deleted!', 'Notification has been deleted.', 'success');
        fetchData();
      } catch (error) {
        console.error('Error deleting notification:', error);
        Swal.fire('Error', 'Failed to delete notification.', 'error');
      }
    }
  };

  const handleDeletePdf = async (id) => {
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'This PDF will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirm.isConfirmed) {
      try {
        await axios.delete(` https://maibil-horticultural-services-1.onrender.com/delete-pdf/${id}`);
        Swal.fire('Deleted!', 'PDF has been deleted.', 'success');
        fetchData();
      } catch (error) {
        console.error('Error deleting PDF:', error);
        Swal.fire('Error', 'Failed to delete PDF.', 'error');
      }
    }
  };

  return (
    <>
      <Navbar />
     
      <div id="admin-page" >
        <h1 id='adminNotification' >Admin Dashboard</h1>

        {/* Notification Section */}
        <div id="notification-section">
          <h2 id='adminNotification' >Enter Website Notification</h2>
          <form onSubmit={handleNotificationSubmit}>
            <textarea
              value={notification}
              onChange={(e) => setNotification(e.target.value)}
              placeholder="Enter your notification here..."
              rows="4"
             
              id="notification-textarea"
            />
            <button type="submit" id="submit-button" >
              Submit Notification
            </button>
          </form>
        </div>

        {/* PDF Upload Section */}
        <div id="upload-section">
          <h2 id='adminNotification'  >Upload PDF</h2>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            id="file-input"
           
          />
          <button onClick={handleUpload} id="upload-button" >
            Upload PDF
          </button>
        </div>

        {/* Display All Notifications */}
        <div id="latest-data-section">
          <h2 id='adminNotification'  >All Notifications</h2>
          {notifications.length > 0 ? (
            notifications.map((notif) => (
              <div key={notif.id} className="notification-item" >
                <h3>{notif.title}</h3>
                <p>{notif.message}</p>
                <p>{new Date(notif.created_at).toLocaleString()}</p>
                <button onClick={() => handleDeleteNotification(notif.id)} className="delete-button" >🗑️</button>
              </div>
            ))
          ) : (
            <p id='adminNotification' >No notifications yet.</p>
          )}

          <h2 id='adminNotification' >All PDFs</h2>
          {pdfs.length > 0 ? (
            pdfs.map((pdf) => (
              <div key={pdf.id} className="pdf-item" >
                <h3>{pdf.file_name}</h3>
                {/* Ensure the URL is correct for viewing the PDF */}
                <a href={` https://maibil-horticultural-services-1.onrender.com/uploads/${pdf.file_name}`} target="_blank" rel="noopener noreferrer" s>View PDF</a>
                <p>{new Date(pdf.upload_time).toLocaleString()}</p>
                <button onClick={() => handleDeletePdf(pdf.id)} className="delete-button" >🗑️</button>
              </div>
            ))
          ) : (
            <p id='adminNotification' >No PDFs uploaded yet.</p>
          )}
        </div>
      </div>
     
      <Footer />
    </>
  );
};

export default AdminPage;
