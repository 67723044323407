import React from 'react';
import '../Styles/Banner.css'; // Import the CSS file for styling

const Banner = () => {
  return (
    <div className="banner">
      
      <div className="header">
        {/* Reference the logo directly from the public folder */}
        <img src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`} alt="Mibal Logo" className="banner-logo" />
        <h1 id='maibalheading'>MAIBIL HORTICULTURAL SERVICES</h1>
        <p className='HighDensity '>Deals With High Density Apple Plantation Services</p>
      </div>
      <div className="images">
        <img src="apple3.webp" alt="Apple Plantation" />
        <img src="apple4.jpg" alt="Apple Trees" />
        <img src="apple1.jpg" alt="Apples in Orchard" />
      </div>
      <div className="address">
        <p id='ADDRESSb'>ADDRESS: 3RD FLOOR, ASH MALL, BUDGAM 191111</p>
      </div>
      {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/7oNuxadecas?si=WkP7fcPjyiYXihtW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
    </div>
  );
};

export default Banner;
