import React from 'react';
import '../Styles/AboutUs.css'; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us-container">
        <h1 className="about-us-title">About Us</h1>
        <p className="about-us-description">
          Welcome to MAIBIL HORTICULTURAL SERVICES. We specialize in providing high-quality apple plantation services and guidance to farmers. Our goal is to revolutionize farming by integrating modern technology with traditional horticulture practices. With years of experience in the industry, we help farmers achieve better yields, and investors make informed decisions.
        </p>
        <div className="about-us-stats">
          <div className="stat-item">
            <h2>100+</h2>
            <p>Successful Projects</p>
          </div>
          <div className="stat-item">
            <h2>500+</h2>
            <p>Happy Farmers</p>
          </div>
          <div className="stat-item">
            <h2>50+</h2>
            <p>Investor Insights</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
