import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../Styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [pdfCount, setPdfCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [expandedNotifications, setExpandedNotifications] = useState({});
  const [viewedNotifications, setViewedNotifications] = useState(new Set());
  const [viewedPdfs, setViewedPdfs] = useState(new Set());
  const [selectedNotification, setSelectedNotification] = useState(null); // Added state for selected notification
  
  useEffect(() => {
    fetchNotifications();

    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar-notification')) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(' https://maibil-horticultural-services-1.onrender.com/fetch-data');
      setNotifications(response.data.notifications || []);
      setNotificationCount(response.data.notifications.length);
      setPdfs(response.data.pdfs || []);
      setPdfCount(response.data.pdfs.length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      Swal.fire('Error', 'Failed to fetch notifications.', 'error');
    }
  };

  const handleNotificationClick = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleNotificationRead = (notification) => {
    if (!viewedNotifications.has(notification.id)) {
      setNotificationCount((prevCount) => Math.max(prevCount - 1, 0));
      setViewedNotifications((prev) => new Set(prev).add(notification.id));
    }
    setExpandedNotifications((prev) => ({
      ...prev,
      [notification.id]: true,
    }));
    setSelectedNotification(notification); // Set selected notification
    setIsDropdownVisible(true);
  };

  const handlePdfView = (notif) => {
    if (!viewedPdfs.has(notif.id)) {
      setPdfCount((prevCount) => Math.max(prevCount - 1, 0));
      setViewedPdfs((prev) => new Set(prev).add(notif.id));

      // Dynamically add 'visited' class
      const link = document.getElementById('viewdoc');
      if (link) {
        link.classList.add('visited'); // Add the visited class when the PDF link is clicked
      }
    }
  };

  const closePopup = () => {
    setSelectedNotification(null);
  };

  const totalCount = notificationCount + pdfCount; // Combined count

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/">
            <img src="maibal_horticulture_services logo.png" alt="Mibal Logo" />
          </Link>
        </div>

        <div className="navbar-middle">
          <Link to="/AboutHDAP">About HDAP</Link>
          <Link to="/FarmersGuide">Farmers Guide</Link>
          <Link to="/InvestorInsights">Investor Insights</Link>
        </div>

        <div className="navbar-notification" onClick={handleNotificationClick}>
          <FontAwesomeIcon icon={faBell} size="lg" />
          {totalCount > 0 && (
            <span className="notification-count">{totalCount}</span>
          )}

          {isDropdownVisible && (
            <div className="notification-dropdown">
              <h3 id='ImportentDc'>Notifications</h3>
              {notifications.length > 0 ? (
                notifications.map((notif) => {
                  const isLongMessage = notif.message.length > 30;
                  const messagePreview = isLongMessage
                    ? `${notif.message.slice(0, 30)}...`
                    : notif.message;

                  return (
                    <div
                      key={notif.id}
                      className={`notification-item ${expandedNotifications[notif.id] ? 'read' : ''}`}
                      onClick={() => handleNotificationRead(notif)}
                    >
                      <strong>{notif.title}</strong>
                      <p>{expandedNotifications[notif.id] ? notif.message : messagePreview}</p>
                      {notif.pdf_file_name && (
                        <div id='viewdoc'>
                          <strong>PDF Available:</strong>
                          <a href={` https://maibil-horticultural-services-1.onrender.com/uploads/${notif.pdf_file_name}`} target="_blank" rel="noopener noreferrer" onClick={() => handlePdfView(notif)}>
                            <span >View Document</span> 
                          </a>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <p className='notification-item'>No notifications available.</p>
              )}

              <h3 id='ImportentDc'> Important Document</h3>

              {pdfs.length > 0 ? (
                pdfs.map((pdf) => {
                  const pdfName = pdf.file_name.split('-').slice(1).join('-').replace('%20', ' '); // Adjust as needed for your encoding

                  return (
                    <div key={pdf.id} className="pdf-item notification-item">
                      <strong>{pdfName}</strong>
                      <a href={` https://maibil-horticultural-services-1.onrender.com/uploads/${pdf.file_name}`} target="_blank" rel="noopener noreferrer" onClick={() => handlePdfView(pdf)}>
                        View Document
                      </a>
                    </div>
                  );
                })
              ) : (
                <p className='notification-item'>No PDFs uploaded yet.</p>
              )}
            </div>
          )}
        </div>
      </nav>

      {selectedNotification && (
        <>
          <div className="notification-popup-background"></div>
          <div className="notification-popup">
            <div className="popup-content">
              <h2>MAIBIL HORTICULTURAL SERVICES</h2>
              <p>We are informing you about the following notification:</p>
              <strong>{selectedNotification.title}</strong>
              <p>{selectedNotification.message}</p>
              {selectedNotification.pdf_file_name && (
                <div>
                  <strong>PDF Available:</strong>
                  <a href={` https://maibil-horticultural-services-1.onrender.com//uploads/${selectedNotification.pdf_file_name}`} target="_blank" rel="noopener noreferrer">
                    View PDF
                  </a>
                </div>
              )}
              <button onClick={closePopup} className="close-popup-btn">Close</button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
