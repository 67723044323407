import React from 'react';
import './App.css'; // Import your CSS file
import AppRoutes from "./Components/AppRoutes"
const App = () => {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
};

export default App;
