import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import "../Styles/SlotBook.css";

const SlotBook = () => {
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+91",
    residence: "",
    location: "",
    farmArea: "",
    estimatedBudget: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      Swal.fire({
        title: "Form Submission Error",
        text: "Please correct the highlighted fields.",
        icon: "error",
        confirmButtonText: "Close",
      });
    } else {
      setLoading(true);
      try {
        const bookingDetails = `
          Name: ${formData.name}\n
          Email: ${formData.email}\n
          Phone: ${formData.countryCode}${formData.phone}\n
          Residence: ${formData.residence}\n
          Location: ${formData.location}\n
          Farm Area: ${formData.farmArea}\n
          Estimated Budget: ${formData.estimatedBudget}
        `;

        // Post data to the backend API
        const response = await axios.post(" https://maibil-horticultural-services-1.onrender.com/send-email", {
          userEmail: formData.email, // user's email input
          userMessage: bookingDetails, // send booking details as message
        });

        // Handle success response
        if (response.data.success) {
          Swal.fire({
            title: "Booking Successful!",
            text: "Thank you for choosing us! Our representative will be in touch with you shortly. We appreciate your patience and look forward to assisting you.",
            icon: "success",
            confirmButtonText: "Close",
          });
          // Optionally reset the form
          setFormData({
            name: "",
            email: "",
            phone: "",
            countryCode: "+91",
            residence: "",
            location: "",
            farmArea: "",
            estimatedBudget: "",
          });
        } else {
          throw new Error("Failed to send email.");
        }

      } catch (error) {
        console.error("Error booking slot:", error);
        Swal.fire({
          title: "Booking Failed!",
          text: "There was an error processing your request. Please try again later.",
          icon: "error",
          confirmButtonText: "Close",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name) errors.name = "Name is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.phone || data.phone.length !== 10) errors.phone = "Valid phone number is required";
    if (!data.residence) errors.residence = "Residence is required";
    if (!data.location) errors.location = "Farm location is required";
    if (!data.farmArea) errors.farmArea = "Please select your farm area";
    if (!data.estimatedBudget) errors.estimatedBudget = "Estimated budget is required";
    return errors;
  };

  const handleClose = () => {
    setShowFormPopup(false);
  };

  return (
    <div id="slotBook-container">
      {!showFormPopup && (
        <div id="slotBook-content">
          <h2 id="slotBook-animated-title">
          ARE YOU LOOKING FOR END TO END PROFESSIONAL SERVICES TO DEVELOP YOUR HIGH DENSITY APPLE ORCHARD?          </h2>
          <Button id="slotBook-btn-primary2" onClick={() => setShowFormPopup(true)}>
            Yes
          </Button>
        </div>
      )}

      {showFormPopup && (
        <div id="slotBook-popup">
          <div id="slotBook-popup-content">
            <Button id="slotBook-btn-close" onClick={handleClose}>
              <CloseIcon />
            </Button>
            <form id="slotBook-form" onSubmit={handleSubmit}>
              {/* Name Field */}
              <TextField
                label="Name"
                
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!formErrors.name}
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={formErrors.name}
              />
              {/* Email Field */}
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={formErrors.email}
              />
              {/* Phone Field */}
              <Box display="flex" alignItems="center" margin="normal">
                <TextField
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!formErrors.phone}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText={formErrors.phone}
                />
              </Box>
              {/* Residence Field */}
              <TextField
                label="Residence"
                name="residence"
                value={formData.residence}
                onChange={handleChange}
                error={!!formErrors.residence}
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={formErrors.residence}
              />
              {/* Location Field */}
              <TextField
                label="Farm Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                error={!!formErrors.location}
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={formErrors.location}
              />
              {/* Farm Area Field */}
              <FormControl fullWidth margin="normal" error={!!formErrors.farmArea}>
                <InputLabel>Farm Area</InputLabel>
                <Select
                  name="farmArea"
                  value={formData.farmArea}
                  onChange={handleChange}
                >




                  <MenuItem value="Less than 1 acre">Less than  2 kanals </MenuItem>
                  <MenuItem value="1-5 acres">2-5 kanals </MenuItem>
                  <MenuItem value="More than 5 acres"> 5-10 kanals </MenuItem>
                  <MenuItem value="More than 5 acres">More than 10   kanals </MenuItem>
                </Select>
                {formErrors.farmArea && (
                  <p id="slotBook-error">{formErrors.farmArea}</p>
                )}
              </FormControl>
              {/* Estimated Budget Field */}
              <TextField
                label="Estimated Budget"
                name="estimatedBudget"
                value={formData.estimatedBudget}
                onChange={handleChange}
                error={!!formErrors.estimatedBudget}
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={formErrors.estimatedBudget}
              />
              {/* Submit Button */}
              <Button
                type="submit"
                id="slotBook-btn-submit"
                variant="contained"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Book Slot"}
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlotBook;
