import React from 'react';
import { Card, CardMedia, Grid} from '@mui/material';
import '../Styles/Gallery.css'; // Importing the CSS file for styling

const Gallery = () => {
  const imageUrl = 'mr bilal.jpg'; // Replace with the correct path to the image
  const videoUrl = 'https://www.youtube.com/embed/Pee3TnC9ANc?si=gxkvUoh-5hgGSdSG';

  return (
    <div className="media-display">
      <Grid container spacing={4} justifyContent="center">
        {/* Image Card */}
        <Grid item xs={12} sm={6}>
          <Card className="media-card">
            <CardMedia
              component="img"
              className="media-image"
              image={imageUrl} // Image URL
              alt="Mr. Bilal"
            />
            
          </Card>
        </Grid>

        {/* Video Card */}
        <Grid item xs={12} sm={6}>
          <Card className="media-card">
          
            <iframe
              className="media-iframe"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Gallery;
