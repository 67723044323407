import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Styles/InvestorInsights.css"; // Import the CSS file

export default function InvestorInsights() {

  
  return (
    <>
      <Navbar />

      <div id="background-imageinverstr">
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h2>INVESTOR INSIGHTS</h2>
        <span>
          Most of the farmers or the investors don’t have an adequate knowledge
          about the HDAP. For their brief insights to make their calibrated
          decisions for their investment in this sector, we hereby provide a
          model project for their informed decision making. This information is
          purely based to the personal experience of the MAIBIL and the
          front-end costs for the installation of the HDAP by acquiring the
          services from our enterprise.
        </span>
        <h4>High-Density Apple Plantation (HDAP) on 10 Kanals of Land</h4>
        <span id="desimpinv">Project Summary</span>
        <p>
          This project report outlines the establishment of a high-density apple
          plantation (HDAP) on an acre plot of land. The objective is to
          maximize the utility of the piece of land. Analyzing the various
          agricultural related projects including establishment of
          bio-fertilizer unit and HDAP, we found HDAP more efficient and
          productive in comparison to the former. In this report, we analyzed
          the project empirically after thorough investigation about the market
          conditions, land fertility, environmental conditions & the pros and
          cons, the imputed costs associated to reduce the impact of certain
          natural disasters, the front-end costs for the extension of the
          existing unit. The report covers the costs involved in land
          preparation, development of the trellis and drip irrigation system,
          additional costs incurred for increasing the water storage and
          effective drip irrigation for the additional one acre of the land
          parcel.
        </p>
        <hr></hr>
        <h4 id="desimpinv"> Project Basics</h4>
        <p>
          {" "}
          <strong>Title:</strong> High-Density Apple Plantation (HDAP)
          <p>
            <strong> Location: </strong> _____________________________________
          </p>
          <p>
            <strong>Land Area:</strong> 1.25 Acre / 10 Kanals / 43520 Sq. ft.
          </p>
          <strong>Planting System:</strong> High-Density system with a tree
          density of 150 trees per kanal of land
        </p>
        <p>
          <strong>Varieties:</strong> Mixture of the early harvesting varieties
          of Gala having best economic potential as per the demand of the market
        </p>
        <p>
          <strong>Rootstocks:</strong> Dwarfing rootstock- M9, the most suitable
          one for HD plantation.
        </p>
        <p>
          <strong>Project Duration:</strong> 20 years- the least expected life
          span of the trees (Establishment period: 3 years, Full production:
          Year 5-6)
        </p>

        <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h3 id="desimpinv">Objectives of the Project</h3>
        <ol>
          <li>
            To establish a high-density apple orchard on one and a quarter acre
            for maximizing productivity.
          </li>
          <li>
            To reduce the gestation period by using dwarfing rootstocks, which
            lead to early fruiting (within 2-3 years).
          </li>
          <li>
            To increase yield per acre and ensure high-quality apple production.
          </li>
          <li>
            To employ sustainable agricultural practices with efficient resource
            use.
          </li>
          <li>
            To provide high returns on investment (ROI) through optimized use of
            land and resources.
          </li>
        </ol>
        <h3 >Land and Soil Preparation</h3>
        <span id="desimpinv">Soil Preparation</span>
        <p>
          The land is flat or gently sloped having expectedly a pH relevant for
          the establishment of HDAP. The soil is loamy having reasonably good
          fertility. The land is reasonably suitable without any doubt. However,
          at the time of land preparation for the establishment of the unit some
          of the soil tests will be done to correct the aberrations from the
          empirical requirements which otherwise may become a permanent
          liability and may incur additional costs to the working capital on
          yearly basis. The tests include, pH, the percentage of the sand, silt
          and clay in the loamy soil, early detection of the deficiency of
          nutrients if any (including the micro nutrients), soil pathogenicity
          tests etc.{" "}
        </p>

        <span id="desimpinv">Selection of Varieties</span>
        <p>
          Choose varieties based on market demand, climate, and disease
          resistance. Popular varieties include Gala, King rot, Fuji, Golden and
          Granny Smith.
        </p>
        <span>Spacing and Planting System</span>
        <ul>
          <li>Tree Density: 1200–1400 trees per acre.</li>
          <li>Row Spacing: 10–12 feet between rows.</li>
          <li>Plant Spacing: 2.5–3.5 feet between plants within a row.</li>
        </ul>
        <hr></hr>

       
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
      
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h3>Irrigation and Water Management</h3>
        <span id="desimpinv">Water Requirements</span>
        <p>
          Apple trees require consistent moisture, especially in the initial
          years and during the fruiting season. Drip irrigation is the most
          suitable method for HD apple orchards as it provides precise water and
          nutrient delivery.
        </p>
        <span id="desimpinv">Irrigation Infrastructure</span>
        <span>
          Drip Irrigation System: Install drip lines with emitters spaced along
          the rows to ensure uniform water distribution.
        </span>

        <span>
          Water Source: Ensure the availability of a reliable water source such
          as a borewell, river, or rainwater harvesting system. We have readily
          available water source from the nearby stream.{" "}
        </span>
        <h3>Nutrient Management and Fertilization</h3>
        <span id="desimpinv">Organic Fertilization</span>
        <p>
          Incorporate well-decomposed organic manure (cow dung, compost) during
          planting to improve soil fertility.
        </p>
        <span id="desimpinv">Fertilization Schedule</span>
        <p>
          <strong>Year 1-2:</strong> Apply balanced fertilizers (NPK) to support
          vegetative growth.
        </p>
        <p>
          <strong>Year 3 and onwards:</strong> Use specific fertilizers based on
          the crop's nutrient demand, applying nitrogen, phosphorus, and
          potassium at regular intervals. Calcium and Magnesium is provided as
          per the soil testing.
        </p>
        <p>
          <strong>Micronutrients:</strong> Supplement with micronutrients (zinc,
          iron, boron) as needed based on soil tests.
        </p>
        <h3>Pest and Disease Management</h3>

        <p>
          <strong>Common Pests:</strong> Codling Moth, Apple Maggot, Aphids
        </p>
        <p>
          <strong>Common Diseases:</strong> Apple Scab, Powdery Mildew, Fire
          Blight
        </p>
        <span>Integrated Pest Management (IPM)</span>
        <p>
          Implement IPM practices by using biological control agents, pheromone
          traps, and timely applications of organic or chemical pesticides.
        </p>

        <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h3>Infrastructure and Capital Requirements</h3>
        <span id="desimpinv">Fencing and Protection</span>
        <p>
          <strong>Fencing:</strong> Erect fencing around the plantation to
          prevent animal intrusion. Little more is expected to improve it if the
          budget allows for it.
        </p>
        <p>
          <strong>Trellising:</strong> Install trellising or support systems to
          train the trees and prevent them from breaking under the weight of
          fruit. We have to improve our trellis system in the existing orchard.
          The trellis system is to be properly developed for effective support
          of the trees.
        </p>

        <span id="desimpinv">Storage and Transportation</span>
        <p>
          Establish a small storage facility on-site to store apples before
          transportation to markets or cold storage facilities. We have a proper
          store for short storage, no additional investment is required for this
          purpose.
        </p>
        <h3>Financial Projections</h3>
        <span id="desimpinv">Initial Investment:</span>
        <span>
         <strong>Land Preparation: </strong> Plowing, leveling, and nutrient management: the
          costs involved are outside the projected cost for orchard development
          on the given area of land.
        </span>

        <span>
        <strong> Planting Material:</strong>  Apple saplings on dwarf rootstocks: to be provided
          by the enterprise, the responsibility of the enterprise shall remain
          to the true to type and mortality (if any above 10% in the first
          offshoot in spring.{" "}
        </span>
        <span>
        <strong> Irrigation System: </strong>  Drip irrigation setup, pumps, and water storage (In
          this, the drip irrigation system shall be developed by the enterprise
          while as the pumps and water storage has to be developed
          independently, if required.
        </span>
        <span>   <strong>Infrastructure:</strong>  Fencing, trellising, storage facility.</span>
        <span id="desimpinv" >Operational Costs</span>
        <p>
          <strong>Labor:</strong> Regular maintenance, pruning, and training of
          trees.
        </p>
        <p>
          <strong>Fertilizers and Pesticides:</strong> Ongoing inputs for
          optimal growth and pest control.
        </p>
        <p>
          <strong>Irrigation Costs:</strong> Watering during dry months and
          energy costs for running pumps and other miscellaneous activities.
        </p>

        <span  id="desimpinv">Expected Yield and Returns</span>
        <p>
          <strong>Yield:</strong> Approximately 15–25 tons per acre by the 4th
          or 5th year (An average of 17 kg per tree having a density of 150
          plants per kanal of land).
        </p>
        <p>
          <strong>Price:</strong> Average market price of Rs 70 -170 per kg (can
          vary).
        </p>
        <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <table border="1" cellpadding="10">
          <thead>
            <tr>
              <th>Activity</th>
              <th>Year 1</th>
              <th>Year 2</th>
              <th>Year 3</th>
              <th>Year 4</th>
              <th>Year 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Land Preparation</td>
              <td>√</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Planting</td>
              <td>√</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Drip Irrigation Setup</td>
              <td>√</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Fertilization</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
            </tr>
            <tr>
              <td>Pruning & Training</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
            </tr>
            <tr>
              <td>Pest Management</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
            </tr>
            <tr>
              <td>Harvesting</td>
              <td></td>
              <td></td>
              <td>√</td>
              <td>√</td>
              <td>√</td>
            </tr>
          </tbody>
        </table>

        <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h3>
          Economics of High-Density Apple Plantation (HDAP) on 1.25 Acre/ 10
          Kanals of Land.
        </h3>
        <p>
          This report presents the economic feasibility of setting up a
          high-density apple plantation (HDAP) on a 1-acre plot of land in
          India. The analysis will include the initial investment, operational
          costs, and revenue projections, based on the latest agricultural
          practices and pricing trends in India. Note: The Currency is in INR. (
          Indian Rupees){" "}
        </p>

        <table border="1" cellpadding="10">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>AREA OF THE LAND (In Kanals) for establishing HDAP</td>
              <td>10</td>
            </tr>
            <tr>
              <td>
                Cost incurred (per Kanal) for the services offered by the
                enterprise
              </td>
              <td>168,825</td>
            </tr>
            <tr>
              <td>
                Total cost incurred for the services offered by the enterprise
              </td>
              <td>1,688,250</td>
            </tr>
            <tr>
              <td>
                Additional investment besides cost incurred by the enterprise
              </td>
              <td>115,000.00</td>
            </tr>
            <tr>
              <td>
                Total Initial Investment for the establishment of the unit (Year
                1)
              </td>
              <td>1,803,250.00</td>
            </tr>
            <tr>
              <td>
                Average Annual operational cost/working capital per kanal of
                land
              </td>
              <td>19,491.02</td>
            </tr>
            <tr>
              <td>
                Average revenue from the sale of fruits on yearly basis at base
                price (Base Year 2024)
              </td>
              <td>311,040</td>
            </tr>
            <tr>
              <td>Break even Point (Year)</td>
              <td>4th Year</td>
            </tr>
            <tr>
              <td>Break even Point Year Surplus</td>
              <td>458,917.50</td>
            </tr>
            <tr>
              <td>Average Net Profits per kanal in a year</td>
              <td>268,762.73</td>
            </tr>
            <tr>
              <td>Average Net Profits in a Year</td>
              <td>2,687,627.28</td>
            </tr>
            <tr>
              <td>Total Net Profits over a period of 20 years</td>
              <td>53,752,545.52</td>
            </tr>
            <tr>
              <td>
                Average returns on investment (fixed cost & working Capital)
              </td>
              <td>9.67</td>
            </tr>
            <tr>
              <td>Average returns on Capital investment</td>
              <td>29.81</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h2>PLANT ESTABLISHMENT COST ANALYSIS</h2>

<table border="1" cellpadding="10">
  <thead>
    <tr>
      <th>Particulars</th>
      <th>Details</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>High-Density Apple Plantation Project</td>
      <td></td>
    </tr>
    <tr>
      <td>Number of Kanals</td>
      <td>10</td>
    </tr>
    <tr>
      <td>Land Preparation</td>
      <td>100,000</td>
    </tr>
    <tr>
      <td>Soil Testing & Amendments</td>
      <td>10,500.00</td>
    </tr>
    <tr>
      <td>Planting Material (Saplings)</td>
      <td>900,000</td>
    </tr>
    <tr>
      <td>Drip Irrigation Setup</td>
      <td>290,000</td>
    </tr>
    <tr>
      <td>Trellising/Support System</td>
      <td>390,000</td>
    </tr>
    <tr>
      <td>Fencing</td>
      <td></td>
    </tr>
    <tr>
      <td>Water Storage, Motor, Generator, Tubewell Development or Water Carrying Expenditure</td>
      <td></td>
    </tr>
    <tr>
      <td>Storage Infrastructure</td>
      <td></td>
    </tr>
    <tr>
      <td>Labor (Initial Planting)</td>
      <td>15,000.00</td>
    </tr>
    <tr>
      <td>Total Investment for Soil Testing and Amendments, Saplings, Trellis System, and Drip Irrigation System</td>
      <td>1,590,500.00</td>
    </tr>
    <tr>
      <td>Transportational Charges for Carrying Trellis and Drip Irrigation Raw Materials at the Site</td>
      <td>42,500.00</td>
    </tr>
    <tr>
      <td>Professional Charges</td>
      <td>12,750.00</td>
    </tr>
    <tr>
      <td>Entrepreneur</td>
      <td>42,500.00</td>
    </tr>
    <tr>
      <td>Total Cost Incurred Through the Enterprise</td>
      <td>1,688,250.00</td>
    </tr>
    <tr>
      <td>Additional Costs Incurred by the Proprietor</td>
      <td>115,000.00</td>
    </tr>
    <tr>
      <td>Total Initial Investment for the Establishment of the Unit (Year 1)</td>
      <td>1,803,250.00</td>
    </tr>
  </tbody>
</table>

<hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>




<h2>Annual Operational Costs / Working Capital at Base Prices (In INR) (Base Year 2024)</h2>
<div  class="table-responsive">
<table>
    <thead>
        <tr>
            <th>Components / Year</th>
            <th>Fertilizers and Nutrients</th>
            <th>Pesticides & Pest Management</th>
            <th>Irrigation & Water Costs</th>
            <th>Labor (Maintenance)</th>
            <th>Pruning and Tree Training</th>
            <th>Harvesting Costs</th>
            <th>Renovation and Replacement</th>
            <th>Total</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Year 1</td>
            <td>50,000.00</td>
            <td>15,000.00</td>
            <td>1,500.00</td>
            <td>30,000.00</td>
            <td>9,000.00</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>143,040.00</td>
        </tr>
        <tr>
            <td>Year 2</td>
            <td>50,000.00</td>
            <td>18,750.00</td>
            <td>1,500.00</td>
            <td>36,000.00</td>
            <td>11,700.00</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>155,490.00</td>
        </tr>
        <tr>
            <td>Year 3</td>
            <td>50,000.00</td>
            <td>23,437.50</td>
            <td>1,501.00</td>
            <td>40,320.00</td>
            <td>14,040.00</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>166,838.50</td>
        </tr>
        <tr>
            <td>Year 4</td>
            <td>50,000.00</td>
            <td>28,125.00</td>
            <td>1,501.00</td>
            <td>44,352.00</td>
            <td>16,146.00</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>177,664.00</td>
        </tr>
        <tr>
            <td>Year 5</td>
            <td>50,000.00</td>
            <td>32,906.25</td>
            <td>1,502.00</td>
            <td>48,787.20</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>188,496.05</td>
        </tr>
        <tr>
            <td>Year 6</td>
            <td>50,000.00</td>
            <td>37,513.13</td>
            <td>1,502.00</td>
            <td>52,690.18</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>197,005.91</td>
        </tr>
        <tr>
            <td>Year 7</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,503.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,973.43</td>
        </tr>
        <tr>
            <td>Year 8</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,503.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,973.43</td>
        </tr>
        <tr>
            <td>Year 9</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,504.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,974.43</td>
        </tr>
        <tr>
            <td>Year 10</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,504.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,974.43</td>
        </tr>
        <tr>
            <td>Year 11</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,505.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,975.43</td>
        </tr>
        <tr>
            <td>Year 12</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,505.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,975.43</td>
        </tr>
        <tr>
            <td>Year 13</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,506.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,976.43</td>
        </tr>
        <tr>
            <td>Year 14</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,506.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,976.43</td>
        </tr>
        <tr>
            <td>Year 15</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,507.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,977.43</td>
        </tr>
        <tr>
            <td>Year 16</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,507.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,977.43</td>
        </tr>
        <tr>
            <td>Year 17</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,508.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,978.43</td>
        </tr>
        <tr>
            <td>Year 18</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,508.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,978.43</td>
        </tr>
        <tr>
            <td>Year 19</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,509.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,979.43</td>
        </tr>
        <tr>
            <td>Year 20</td>
            <td>50,000.00</td>
            <td>41,264.44</td>
            <td>1,509.00</td>
            <td>56,905.39</td>
            <td>17,760.60</td>
            <td>10,000.00</td>
            <td>27,540.00</td>
            <td>204,979.43</td>
        </tr>
        <tr class="total-row">
            <td>Total</td>
            <td>1,000,000.00</td>
            <td>733,434.04</td>
            <td>30,090.00</td>
            <td>1,048,824.84</td>
            <td>335,055.60</td>
            <td>200,000.00</td>
            <td>550,800.00</td>
            <td>3,898,204.48</td>
        </tr>
    </tbody>
</table>
</div>
<hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>
        <h2>Revenue from the Sale of Fruits (Yearly Basis)</h2>
    <table>
        <tr>
            <th>Parameter/Year</th>
            <th>Production (In Kgs)</th>
            <th>Price (In INR)</th>
            <th>Total Revenue (In INR)</th>
        </tr>
        <tr>
            <td>1</td>
            <td>0</td>
            <td>120</td>
            <td>0</td>
        </tr>
        <tr>
            <td>2</td>
            <td>3200</td>
            <td>120</td>
            <td>384000</td>
        </tr>
        <tr>
            <td>3</td>
            <td>9600</td>
            <td>120</td>
            <td>1152000</td>
        </tr>
        <tr>
            <td>4</td>
            <td>16000</td>
            <td>120</td>
            <td>1920000</td>
        </tr>
        <tr>
            <td>5</td>
            <td>19200</td>
            <td>120</td>
            <td>2304000</td>
        </tr>
        <tr>
            <td>6</td>
            <td>25600</td>
            <td>120</td>
            <td>3072000</td>
        </tr>
        <tr>
            <td>7</td>
            <td>28800</td>
            <td>120</td>
            <td>3456000</td>
        </tr>
        <tr>
            <td>8</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>9</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>10</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>11</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>12</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>13</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>14</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>15</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>16</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>17</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>18</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>19</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr>
            <td>20</td>
            <td>32000</td>
            <td>120</td>
            <td>3840000</td>
        </tr>
        <tr class="total-row">
            <td><strong>Total</strong></td>
            <td><strong>518400</strong></td>
            <td><strong>-</strong></td>
            <td><strong>62208000</strong></td>
        </tr>
    </table>



    <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h2>Depreciation Analysis</h2>
    <h4>VALUE AT BASE PRICE, BASE YEAR 2024</h4>
    <div  className="table-responsive ">
    <table>
        <tr>
            <th>Particulars/ Year</th>
            <th>Planting Material (Saplings)</th>
            <th>Drip Irrigation Setup</th>
            <th>Trellising / Support System</th>
            <th>Fencing, Water Storage, Motor, Generator, Tubewell Development or Water Carrying Expenditure</th>
            <th>Storage Infrastructure</th>
            <th>Grand Total</th>
        </tr>
        <tr>
            <td>1</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>2</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>3</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>4</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>5</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>6</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>7</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>8</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>9</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>10</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>11</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>12</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>13</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>14</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>15</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>16</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>17</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>18</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>19</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr>
            <td>20</td>
            <td>63000</td>
            <td>43500</td>
            <td>31200</td>
            <td>0</td>
            <td>0</td>
            <td>137700</td>
        </tr>
        <tr className="total-row">
            <td><strong>Total</strong></td>
            <td><strong>1260000</strong></td>
            <td><strong>870000</strong></td>
            <td><strong>624000</strong></td>
            <td><strong>0</strong></td>
            <td><strong>0</strong></td>
            <td><strong>2754000</strong></td>
        </tr>
    </table>
    </div>
    <hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>
        <h2>Profit and Loss Analysis (At Base Price - Base Year: 2024)</h2>
        <div  class="table-responsive">
<table border="1" >
    <thead>
        <tr>
            <th>Particulars/ Year</th>
            <th>Total Initial Investment for the Establishment of the Unit</th>
            <th>Annual Operational Cost/ Working Capital at Base Price</th>
            <th>Depreciation of Assets</th>
            <th>Sale of the Produce</th>
            <th>Gross Profits</th>
            <th>Tax</th>
            <th>Net Profit (At Base Price)</th>
            <th>Cumulative Surplus at the End of Year (At Base Price)</th>
            <th>Break Even Point (BEP)</th>
            <th>Return on Investment (RoI) (In %)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>1803250</td>
            <td>143040</td>
            <td>137700</td>
            <td>0</td>
            <td>-2083990</td>
            <td>0</td>
            <td>-2083990</td>
            <td>-2083990</td>
            <td>N.D.</td>
            <td>-107.07</td>
        </tr>
        <tr>
            <td>2</td>
            <td>0</td>
            <td>155490</td>
            <td>137700</td>
            <td>384000</td>
            <td>90810</td>
            <td>0</td>
            <td>90810</td>
            <td>-1993180</td>
            <td>7.89</td>
            <td>58.4</td>
        </tr>
        <tr>
            <td>3</td>
            <td>0</td>
            <td>166838.5</td>
            <td>137700</td>
            <td>1152000</td>
            <td>847461.5</td>
            <td>0</td>
            <td>847461.5</td>
            <td>-1145719</td>
            <td>1.83</td>
            <td>507.95</td>
        </tr>
        <tr>
            <td>4</td>
            <td>0</td>
            <td>177664</td>
            <td>137700</td>
            <td>1920000</td>
            <td>1604636</td>
            <td>0</td>
            <td>1604636</td>
            <td>458917.5</td>
            <td>1.26</td>
            <td>903.19</td>
        </tr>
        <tr>
            <td>5</td>
            <td>0</td>
            <td>188496.1</td>
            <td>137700</td>
            <td>2304000</td>
            <td>1977804</td>
            <td>0</td>
            <td>1977804</td>
            <td>2436721</td>
            <td>1.01</td>
            <td>1049.25</td>
        </tr>
        <tr>
            <td>6</td>
            <td>0</td>
            <td>197005.9</td>
            <td>137700</td>
            <td>3072000</td>
            <td>2737294</td>
            <td>0</td>
            <td>2737294</td>
            <td>5174016</td>
            <td>0.71</td>
            <td>1389.45</td>
        </tr>
        <tr>
            <td>7</td>
            <td>0</td>
            <td>204973.4</td>
            <td>137700</td>
            <td>3456000</td>
            <td>3113327</td>
            <td>0</td>
            <td>3113327</td>
            <td>8287342</td>
            <td>0.62</td>
            <td>1518.89</td>
        </tr>
        <tr>
            <td>8</td>
            <td>0</td>
            <td>204973.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497327</td>
            <td>0</td>
            <td>3497327</td>
            <td>11784669</td>
            <td>0.55</td>
            <td>1706.23</td>
        </tr>
        <tr>
            <td>9</td>
            <td>0</td>
            <td>204974.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497326</td>
            <td>0</td>
            <td>3497326</td>
            <td>15281994</td>
            <td>0.55</td>
            <td>1706.23</td>
        </tr>
        <tr>
            <td>10</td>
            <td>0</td>
            <td>204974.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497326</td>
            <td>0</td>
            <td>3497326</td>
            <td>18779320</td>
            <td>0.55</td>
            <td>1706.23</td>
        </tr>
        <tr>
            <td>11</td>
            <td>0</td>
            <td>204975.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497325</td>
            <td>0</td>
            <td>3497325</td>
            <td>22276644</td>
            <td>0.55</td>
            <td>1706.22</td>
        </tr>
        <tr>
            <td>12</td>
            <td>0</td>
            <td>204975.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497325</td>
            <td>0</td>
            <td>3497325</td>
            <td>25773969</td>
            <td>0.55</td>
            <td>1706.22</td>
        </tr>
        <tr>
            <td>13</td>
            <td>0</td>
            <td>204976.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497324</td>
            <td>0</td>
            <td>3497324</td>
            <td>29271293</td>
            <td>0.55</td>
            <td>1706.21</td>
        </tr>
        <tr>
            <td>14</td>
            <td>0</td>
            <td>204976.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497324</td>
            <td>0</td>
            <td>3497324</td>
            <td>32768616</td>
            <td>0.55</td>
            <td>1706.21</td>
        </tr>
        <tr>
            <td>15</td>
            <td>0</td>
            <td>204977.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497323</td>
            <td>0</td>
            <td>3497323</td>
            <td>36265939</td>
            <td>0.55</td>
            <td>1706.2</td>
        </tr>
        <tr>
            <td>16</td>
            <td>0</td>
            <td>204977.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497323</td>
            <td>0</td>
            <td>3497323</td>
            <td>39763261</td>
            <td>0.55</td>
            <td>1706.2</td>
        </tr>
        <tr>
            <td>17</td>
            <td>0</td>
            <td>204978.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497322</td>
            <td>0</td>
            <td>3497322</td>
            <td>43260583</td>
            <td>0.55</td>
            <td>1706.19</td>
        </tr>
        <tr>
            <td>18</td>
            <td>0</td>
            <td>204978.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497322</td>
            <td>0</td>
            <td>3497322</td>
            <td>46757905</td>
            <td>0.55</td>
            <td>1706.19</td>
        </tr>
        <tr>
            <td>19</td>
            <td>0</td>
            <td>204979.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497321</td>
            <td>0</td>
            <td>3497321</td>
            <td>50255226</td>
            <td>0.55</td>
            <td>1706.18</td>
        </tr>
        <tr>
            <td>20</td>
            <td>0</td>
            <td>204979.4</td>
            <td>137700</td>
            <td>3840000</td>
            <td>3497321</td>
            <td>0</td>
            <td>3497321</td>
            <td>53752547</td>
            <td>0.55</td>
            <td>1706.18</td>
        </tr>
    </tbody>
</table>
</div>

<hr></hr>
        <h1 id="invhead">MAIBIL HORTICULTURAL SERVICES</h1>
        <div id="invslgo">
          <img
            src={`${process.env.PUBLIC_URL}/maibal_horticulture_services logo.png`}
            alt="Mibal Logo"
            className="banner-logo"
          />
          <div>
            <span id="adrinvs">3RD FLOOR, ASH MALL BUDGAM</span>
            <span id="emailivvs"> Email: info@maibil.in</span>
          </div>
        </div>

        <h2>Key Abbreviations</h2>

<table border="1">
    <thead>
        <tr>
            <th>Abbreviation</th>
            <th>Meaning</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>HDAP</td>
            <td>High Density Apple Plantation</td>
        </tr>
        <tr>
            <td>Inr.</td>
            <td>Currency in Indian Rupees</td>
        </tr>
        <tr>
            <td>NPK</td>
            <td>Nitrogen, Phosphorus and Potassium</td>
        </tr>
        <tr>
            <td>RoI</td>
            <td>Return on Investment</td>
        </tr>
    </tbody>
</table>

<span id="desimpinv">Disclaimer</span>

<p>This project is based on the empirical findings as observed by the team of <strong>MAIBIL
HORTICULTURAL SERVICES.</strong> The projections are based on the average prices of different
apple varieties for the Year 2024. The projections for the subsequent years is prepared while
keeping the real value of the produce and profits, eliminating the depreciation due to the average
inflation and other factors. Accordingly, we have made the projection while fixing the Base Year
for the realistic projections. Considering the returns on investment, we highly recommend for an
entrepreneur’s investment in the high density apple plantation sector which is emerging in India
and the expectations of the demand of the apple varieties is expected to remain intact for atleast
next few decades.
</p>
<div id="invest-former">
  <section className="contact-info">
            <h1 id="invhead">Contact Information</h1>
            <p>For any queries regarding this project, kindly contact the Project Manager,</p>
            <p><strong>Dr. Bilal Ahmad Bhat</strong></p>
            <p><strong>Phone Numbers:</strong> 9541786520, 6006654699</p>
            <p><strong>Email:</strong> <a href="mailto:drbabhat01@gmail.com">drbabhat01@gmail.com</a></p>
            </section>
        </div>

      </div>
      <Footer />
    </>
  );
}
