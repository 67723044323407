import React, { useState, useEffect } from 'react';
import '../Styles/Footer.css'; // Import the external CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,  faInstagram } from '@fortawesome/free-brands-svg-icons'; // Importing social media icons

function Footer() {
  const [currentDateTime, setCurrentDateTime] = useState('');

  // Function to update date and time every second
  useEffect(() => {
    const timer = setInterval(() => {
      const date = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      setCurrentDateTime(date.toLocaleDateString('en-US', options));
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="footer-container">
      <div className="footer-top">
        {/* Contact Us Column */}
        <div className="footer-column">
          <h3 className="footer-heading">Contact Us</h3>
          <p className="footer-text"> 3RD FLOOR,  ASH MALL BUDGAM 191111</p>
          <p className="footer-text">Phone: +919541786524, +919541786520, +918082555851</p>
          <p className="footer-text">Email: info@maibil.in</p>
        </div>

        {/* Follow Us Column */}
        <div className="footer-column">
          <h3 className="footer-heading">Follow Us</h3>
          <div>
            <a href="https://www.facebook.com/people/Maibil-Horticultural-Services/61567386569445/?is_tour_co" className="footer-social-icon">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            
            <a href="https://www.instagram.com/maibil_2024" className="footer-social-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} MAIBIL HORTICULTURAL SERVICES. All Rights Reserved.
        </p>
        <p className="footer-datetime">{currentDateTime}</p>
      </div>
    </div>
  );
}

export default Footer;




